import React from "react";
import { Container,Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import GETLINK from "../../getlink";
import HtmlParser from "react-html-parser";
import { getSpacingClass } from "../../common-data/common-data";
import "./IntroModule.scss";

const IntroModule = ({ ModuleData }) => {
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  return (
    <div className={`intro-module ${spacingClass}`}>
      <Container>
        <Row>
          <Col md={12} lg={4}>
           <div className="left-content">
               {HtmlParser(ModuleData?.Content_Column_Left)}
           </div>
          </Col>
          <Col md={12} lg={{ span: 7, offset: 1 }}>
            <div className="right-content">
                 {HtmlParser(ModuleData?.Content_Column_Right)}
                 {ModuleData.Child_Pages && ModuleData.Child_Pages.length > 0 &&
                 <div className="cta">
                      <ul className="child-pages">
                          {ModuleData.Child_Pages.map((node, key) => (
                              <li key={key} className="child-page-item">
                                  {node.Child_Page_Custom_Link ?
                                      <Link to={node.Child_Page_Custom_Link}>{node.Child_Page_Label}</Link>
                                      :
                                      <GETLINK label={node.Child_Page_Label} link={node.Child_Page_Link && node.Child_Page_Link.URL}/>
                                  }
                              </li>
                          ))}
                      </ul>
                 </div>
                }
            </div>
          </Col>
        </Row>
      </Container> 
    </div>
  );
};

export default IntroModule;