import React from "react";
import BannerModule from "../modules/BannerModule/BannerModule";
import IntroModule from "../modules/IntroModule/IntroModule";
import TileBlocksModule from "../modules/TileBlocksModule/TileBlocksModule";
//import StatisticsModule from "../modules/StatisticsModule/StatisticsModule";
import ModuleSelectModule from "../modules/ModuleSelectModule/ModuleSelectModule";
//import StaticIntroModule from "../modules/StaticIntroModule/StaticIntroModule";
//import StaticBannerModule from "../modules/StaticBannerModule/StaticBannerModule";
////import HistoryBlocksModule from "../modules/HistoryBlocksModule/HistoryBlocksModule";
///import ValuationModule from "../modules/ValuationModule/ValuationModule";
import CalculatorsModule from "../modules/CalculatorsModule/CalculatorsModule";
import TableModule from "../modules/TableModule/TableModule";
//import AwardsListingModule from "../modules/AwardsListingModule/AwardsListingModule";
//import ImageBannerContentsModule from "../modules/ImageBannerContentsModule/ImageBannerContentsModule";
import FAQModule from "../modules/FAQModule/FAQModule";
import FormModule from "../modules/FormModule/FormModule";
import FullWidthModule from "../modules/FullWidthModule/FullWidthModule";
import FullWidthTwoColModule from "../modules/FullWidthTwoColModule/FullWidthTwoColModule";
import ElfsightInstaWidgetModule from "../modules/ElfsightInstaWidgetModule/ElfsightInstaWidgetModule";
import ReviewsModule from "@components/Reviews/ReviewsModule";

const moduleMapping = {
  Banner: BannerModule,
  Intro: IntroModule,
  TileBlocks: TileBlocksModule,
  //Statistics: StatisticsModule,
  SelectModules: ModuleSelectModule,
  Reviews: ReviewsModule,
 // StaticIntro: StaticIntroModule,
  //StaticBanner: StaticBannerModule,
 // HistoryBlocks: HistoryBlocksModule,
 // Valuation: ValuationModule,
  Calculators: CalculatorsModule,
  Table: TableModule,
  //AwardListings: AwardsListingModule,
 // ImageBannerContents: ImageBannerContentsModule,
  FAQModule: FAQModule,
  FormModule: FormModule,
  FullWidthModule: FullWidthModule,
  FullWidthTwoCol: FullWidthTwoColModule,
  ElfsightInstaWidget: ElfsightInstaWidgetModule, 
};

const ModuleRenderer = ({ Modules, ...rest }) => {
 
  return (
    <div className="module-renderer">      
      {Modules.map((module, index) => {


        const ModuleComponent = moduleMapping[module.ModuleType];
        
        if (!ModuleComponent) return null;
        return (
          <ModuleComponent
            key={`${module.ModuleType}-${index}`}
            ModuleData={module}
            {...rest}
          />
        );
      })}
    </div>
  );
};

export default ModuleRenderer;
