import React from "react";
import {Container} from "react-bootstrap";
import HtmlParser from 'react-html-parser';
import HomeSearch from "../../../blocks/forms/home-search";
import ElfsightWidget from "../../Elfsight/ElfsightWidget";
import CTARenderer from "../../CTARenderer/CTARenderer";
import ImageRenderer from "../../ImageRenderer/ImageRenderer";
import Img from 'gatsby-image';
import {GoogleReviewIcon} from "../../icon/icon";
import { getSpacingClass } from "../../common-data/common-data";

import "./BannerModule.scss";

const BannerModule = ({ ModuleData, ...PageData }) => {
  // console.log("ModuleData", ModuleData, PageData);
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  const image_url =PageData?.TeamData[0]?.Image?.internal?.description ? PageData?.TeamData[0]?.Image?.internal?.description?.replace("File ","").replace('"','').replace('"','') : '';  
  const h1SpacingClass = ModuleData?.Show_Banner_Title_Spacing ? "spacing-bottom-24":"";
  return (
    <div className={`banner-module ${spacingClass}`}>
      <Container>
        <div className="banner-content">
            <h1 className={`banner-heading ${h1SpacingClass}`}>{ModuleData?.Banner_Title && HtmlParser(ModuleData?.Banner_Title)}</h1>
            <div className="banner-text">
              {ModuleData?.Banner_Content  && HtmlParser(ModuleData?.Banner_Content)}
            </div>
            {ModuleData?.Show_Search  && 
            <div className="banner-form spacing-40">
              <HomeSearch PageAlias={PageData?.PageAlias} />
             </div>}
            
            {(ModuleData?.Banner_CTA_1_URL?.URL || ModuleData?.Banner_CTA_2_URL?.URL || ModuleData?.Banner_1_Custom_Link ||  ModuleData?.Banner_2_Custom_Link || ModuleData?.Banner_1_CTA_Hash || ModuleData?.Banner_2_CTA_Hash) && 
              <div className="banner-cta spacing-40">
                <CTARenderer CTA1Label={ModuleData?.Banner_CTA_1_Label} CustomCTA1Link={ModuleData?.Banner_1_Custom_Link} eventCTA1Hash={ModuleData?.Banner_1_CTA_Hash} CTA1Link={ModuleData?.Banner_CTA_1_URL?.URL} CTA2Label={ModuleData?.Banner_CTA_2_Label} CustomCTA2Link={ModuleData?.Banner_2_Custom_Link}  CTA2Link={ModuleData?.Banner_CTA_2_URL?.URL} eventCTA2Hash={ModuleData?.Banner_2_CTA_Hash} btn1class="btn-yellow" btn2class="btn-transparent-black" />
              </div>
            }

            {ModuleData?.Show_Review &&
            <div className="elfsight-reviews-badge">
                <GoogleReviewIcon />
                <ElfsightWidget widgetID={PageData?.ReviewsData?.Badge_Widget_Code} />
            </div>
            }
            {/* {PageData?.PageAlias ==='home' && PageData?.TeamData &&
              <div className="banner-bottom">
                  <div className="member-image">
                  {image_url != null &&
                      <ImageRenderer ImageSrc={{url:image_url}} altText={PageData?.TeamData[0]?.Name} ggfx_results={PageData?.TeamData[0]?.ggfx_results} strapi_id={PageData?.TeamData[0]?.id} imagename="team.Image.avatar"  />
                      }
                                    
                  </div>
                  <div className="text-20">
                    <span className="text-20 bold">Ready to Sell or Let?</span> Get started today and <Link to={StaticUrls.ValuationPage}>book a valuation</Link>
                  </div>
              </div>
            } */}
        </div>
      </Container>     
    </div>
  );
};

export default BannerModule;